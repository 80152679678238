@import "./vars";

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// COLOR SYSTEMS ROOT ---
:root {
  --primary_color: rgb(184, 75, 150);
}

body {
  font-family: "is_book";
  color: $black;
  line-height: 1.35;
}

.noScroll {
  overflow: hidden !important;
}

a {
  color: $black;
  &:hover {
    text-decoration: none;
    color: $black;
  }
}

h1 {
  line-height: 2.25rem;
}

img {
  max-width: 100%;
}

.cursor {
  cursor: pointer;
}

.trans {
  -webkit-transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
  transition: 0.5s cubic-bezier(0.2, 0.61, 0.41, 0.95);
}

.shadows {
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.05);
}

.bradius {
  border-radius: $border_radius;
}

.padd {
  padding: 2rem;
}

.p-1 {
  padding: 1rem;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.text {
  &.fnt_light {
    font-family: "is_light";
  }
  &.fnt_book {
    font-family: "is_book";
  }
  &.fnt_medium {
    font-family: "is_medium";
  }
  &.fnt_bold {
    font-family: "is_bold";
  }
  &.fnt_black {
    font-family: "is_black";
  }
}

.c_primary {
  color: var(--primary_color) !important;
}

.c_green {
  color: rgb(3, 148, 3);
}

.paragraph {
  font-size: 1rem;
  &.parag_big {
    font-size: 1.2rem;
  }
}

.custom-control-label {
  vertical-align: middle;
  padding-top: 0.15rem;
}

.links_legals {
  position: relative;
  z-index: 1;
  .content_legals {
    display: flex;
    flex-direction: column;
    a {
      cursor: pointer;
      font-size: 0.8rem;
    }
  }
}

.card {
  border-radius: $border_radius;
  border: 0;
  padding: 0.75rem;
}

.box_content_img {
  max-height: 170px;
  min-height: 170px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: $border_radius;
  img {
    border-radius: $border_radius;
    width: 100%;
  }
}

.form-group {
  margin-bottom: 1.25rem;
  .box_input_icon {
    position: relative;
    display: block;
    .form-control {
      padding-left: 37px;
    }
    .ico_input {
      position: absolute;
      left: 12px;
      top: calc(50% - 11px);
      img {
        height: 18px;
      }
    }
  }
  .form-control {
    border-radius: $border_radius;
    height: 45px;
    &:focus {
      color: $black;
      outline: 0;
      border: 0;
    }
  }
}

.dropdown-container,
.dropdown-content {
  border-radius: $border_radius;
  padding: 2px;
  padding-left: 6px;
  background: $bg_grey;
  &:focus {
    color: $black;
    outline: 0;
    border: 0;
  }
}

.dropdown-content {
  padding: 6px;
}

.bg_logo_shop {
  width: 75px;
  height: 75px;
  margin: 0 auto;
  display: flex;
  justify-self: center;
  overflow: hidden;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
}

.dropdown-menu {
  border-radius: 0;
  padding: 0.5rem;
  .dropdown-item {
    padding-left: 0.75rem;
    border-radius: 5px;
    &:hover {
      background: var(--primary_color);
      color: $white_text;
    }
  }
}
label {
  margin-bottom: 0.25rem;
}

.custom-control-input:checked ~ .custom-control-label {
  &::before {
    border-color: var(--primary_color) !important;
    background-color: var(--primary_color) !important;
  }
}

fieldset {
  padding: 1.5rem;
  border: 1px solid #ccc;
  legend {
    display: inline-block;
    width: auto;
    padding: 0 1rem 0 0.5rem;
    font-size: 1.25rem;
    color: #666666;
  }
}

.table-responsive {
  display: block !important;
}

.right-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.85rem;
  .sec_doodle {
    width: auto;
    min-width: 185px;
    height: 55px;
    border-radius: 0.35rem;
    border: 1px solid #dedede;
    background: #eaeaea;
    .box_info_doodle {
      justify-content: center;
    }
  }
}

.sec_doodle {
  width: 250px;
  height: 65px;
  position: relative;
  margin-left: 0.25rem;
  display: block;
  &:hover {
    .info_doodle {
      h6,
      p {
        color: var(--primary_color);
      }
    }
  }
  &.rwd__hide {
    display: none;
  }
  .box_info_doodle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 0.75rem;
    .img_doodle {
      max-height: 30px;
    }
    .info_doodle {
      h6 {
        font-size: 14px;
      }
      p {
        font-size: 12px;
        line-height: 1;
        color: #666666;
      }
    }
  }
  .drawer_particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper_table {
  td,
  th {
    vertical-align: middle;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.5rem 0.3rem;
  }
  thead th {
    font-size: 0.9rem;
  }
  tbody {
    tr {
      border-bottom: 1px solid #dee2e6;
      td {
        border: 0;
      }
    }
  }
  .link_data {
    color: var(--primary_color) !important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .link_data_off {
    color: var(--primary_color);
  }
  .icon_tables {
    display: inline-block;
    margin: 0 0.2rem;
    cursor: pointer;
    &:hover {
      color: var(--primary_color);
    }
  }

  .icon_tables_reject {
    display: inline-block;
    margin: 0 0.2rem;
    cursor: pointer;
    &:hover {
      color: #f00;
    }
  }
}
.min_width {
  min-width: 200px;
}

.tls {
  line-height: 1.1;
  font-size: 0.9rem;
}

.box_filter_messg {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.25rem;
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 6px rgba(97, 97, 97, 0.1);
}

.wrapper_chats .avatarINewGroup .wrapper__bg_flags .contain_avatar .box_avatar {
  width: 150px !important;
  min-width: 150px !important;
  height: 150px !important;
  margin: 0 auto;
  border: 5px solid #dcdce4;
}

.wrapper_calendar {
  overflow: auto;
  min-height: 550px;
  .rbc-header {
    padding: 5px 3px;
    background-color: $bg_grey;
    text-transform: capitalize;
  }
  .rbc-calendar {
    .rbc-toolbar {
      position: relative;
      min-height: 100px;
      & > span {
        margin: 0 auto;
        text-align: center;
        position: absolute;
      }
      .rbc-btn-group {
        bottom: 0;
        button {
          padding: 0.35rem 0.5rem;
        }
        &:first-child {
          left: 0;
        }
        &:last-child {
          right: 0;
        }
      }
      .rbc-toolbar-label {
        top: 0;
        text-transform: capitalize;
        font-family: "is_medium";
        font-size: 2rem;
        padding: 0;
      }
    }
    .rbc-time-view,
    .rbc-month-view,
    .rbc-agenda-view {
      min-width: 500px;
    }
  }
}

.box_data_calendar {
  .item_data_calendar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
    .drops {
      width: 25px;
      height: 25px;
      border-radius: 5px;
      &.event {
        background: #6b9acf;
      }
      &.birthday {
        background: #8cbd6b;
      }
    }
    h6 {
      margin-bottom: 0;
      padding-left: 0.5rem;
    }
  }
}

.line_height {
  line-height: 1;
}

.goback {
  display: flex;
  align-items: center;
  color: #999;
  max-width: 150px;
  cursor: pointer;
  &:hover {
    color: var(--primary_color);
  }
  * {
    line-height: 1;
  }
  span {
    font-weight: 900;
    font-size: 0.85rem;
  }
}

.btn-group {
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
  .btns {
    border: 0;
    padding: 0.5rem 0.75rem;
    outline: none;
    &.active,
    &:hover {
      background: var(--primary_color) !important;
      color: $white_text;
    }
    &.active {
      box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.3);
    }
    &:first-child {
      border-radius: 7px 0 0 7px;
    }
    &:last-child {
      border-radius: 0 7px 7px 0;
    }
  }
}

.btn_bt {
  color: #fefefe;
  font-weight: 800;
  font-size: 1rem;
  padding-top: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  &.b-custom {
    text-align: center;
    padding: 0;
    padding-top: 2px;
    width: 25px;
    height: 25px;
  }
  &.cancel {
    color: #d12929;
    border: 2px solid #d12929;
  }
}

.b_autorizar {
  background: #47c542 !important;
  border: 2px solid #47c542;
}

.btnconfirmar {
  background: #47c542;
  display: block;
  width: 100%;
  padding: 0 0.25rem 0.5rem;
  font-family: "is_bold";
  text-transform: uppercase;
  color: #fefefe;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  i {
    font-size: 1.5rem;
    position: relative;
    top: 6px;
  }
  &:focus,
  &:active {
    outline: none;
  }
}

.btnRechazar {
  background: #c54242;
  display: block;
  width: 100%;
  padding: 0 0.25rem 0.5rem;
  font-family: "is_bold";
  text-transform: uppercase;
  color: #fefefe;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 0.9rem;
  border-radius: 0.25rem;
  i {
    font-size: 1.5rem;
    position: relative;
    top: 6px;
  }
  &:focus,
  &:active {
    outline: none;
  }
}

.files {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > span {
    position: relative;
  }
  .item_file {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cccccc;
    padding: 0.35rem 0.5rem 0.15rem;
    border-radius: 6px;
    margin-left: 0.5rem;
    width: 100%;
    max-width: 120px;
    .ico_file {
      width: 20px;
      font-size: 1.25rem;
      margin-right: 0.35rem;
    }
    small {
      font-size: 0.75rem;
      max-width: 95px;
      word-break: break-all;
      line-height: 1;
    }
    &:hover {
      opacity: 0.75;
    }
  }
  .btn_delete {
    background: #d80505f1;
    color: $white_text;
    cursor: pointer;
    position: absolute;
    box-shadow: -3px 1px 6px rgba(0, 0, 0, 0.25);
    top: -8px;
    left: 5px;
    width: 20px;
    height: 20px;
    padding-left: 5px;
    padding-top: 2px;
    border-radius: 50%;
    font-size: 0.75rem;
  }
}

.settingsWorld {
  .wrapper_box_stories {
    min-height: 270px;
  }
}

.wrapper__modal_infog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  text-align: center;
  overflow: auto;
  padding: 1rem;
  display: none;
  &.inShow {
    display: block;
  }
  .btnInCLose {
    background: #ffffff;
    font-size: 0.8rem;
    font-weight: 800;
    padding: 0.25rem;
    border-radius: 0.25rem;
    position: fixed;
    z-index: 99;
    top: 0.5rem;
    letter-spacing: 1px;
    right: 1rem;
    box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }
  .modal_infog {
    max-width: 525px;
    margin: 0 auto;
    .imginmodal {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.wrapper_modal_doodle {
  background-color: rgba(10, 1, 9, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  &.inShow {
    display: flex;
  }
  .modal_content {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    .btnInCLose {
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(255, 255, 255, 1);
      font-size: 0.7rem;
      padding: 0.15rem 0.35rem;
      opacity: 0.5;
      border-radius: 0.25rem;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    img {
      max-height: 450px;
    }
  }
}

.wrapper_dropzone {
  margin-bottom: 2rem;
  .dropzone {
    background: $white_text;
    padding: 1rem;
    .box_dropzone {
      border-radius: 7px;
      border: 2px dashed #c7072e;
      margin: 0;
      padding: 2rem 0.5rem;
      text-align: center;
      span {
        font-size: 3rem;
      }
    }
  }
}

.nav-tabs {
  overflow: auto;
  flex-wrap: nowrap;
  border: 0;
  @include scrollbar_horizontal;
  .nav-item {
    margin: 0;
    .nav-link {
      margin: 0;
      min-width: 150px;
      width: 100%;
      text-align: center;
      cursor: pointer;
      &.active {
        font-family: "is_medium";
        color: var(--primary_color);
      }
    }
  }
}

.brox_for_video {
  display: none;
  &.is_show {
    display: block;
  }
}
.box_tabs_avisos {
  .nav-item {
    width: 100%;
  }
  .card_tabs {
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
    padding-top: 2rem !important;
  }
  iframe,
  .box_videos {
    width: 100%;
  }
}

.btn {
  font-family: "is_medium";
  border-radius: 30px;
  min-width: 200px;
  height: 45px;
  padding: 10px;
  position: relative;
  cursor: pointer !important;
  &:hover,
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
    &:before {
      height: 100%;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: rgba(255, 255, 255, 0.15);
    -webkit-transition: 0.3s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    transition: 0.3s cubic-bezier(0.2, 0.61, 0.41, 0.95);
    border-radius: 30px;
  }
  &.primary {
    background: var(--primary_color);
    color: #ffffff !important;
    border: 0;
  }
  &.secundary {
    border: 2px solid var(--primary_color);
    color: var(--primary_color);
  }
  &.minimum {
    height: 33px;
    padding: 0.2rem 2rem;
    min-width: auto;
  }
}

.back_go {
  background: $white_text;
  padding: 0.35rem;
  border-radius: 5px;
  cursor: pointer;
  color: #666666;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  &:hover {
    color: var(--primary_color);
  }
}

.btn_links {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.link_action {
  text-decoration: underline;
  color: var(--primary_color);
  cursor: pointer;
}

.section_surveys {
  .slick-list {
    height: 300px;
  }
}
.drawer__cont_svg {
  height: 500px !important;
  width: 100% !important;
  svg {
    width: 100% !important;
  }
}

.form_info_service,
.wrapper_register {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;
  }
  .row {
    margin-right: -7px;
    margin-left: -7px;
  }
  .form-group .form-control {
    border-radius: 9px;
    height: 42px;
    background: #f2f2f2;
  }
}

// ==========
// MEDIDAS DEL BG DE BANDERAS:
// .bgflag ES 12PX MAS QUE .box_avatar SIEMPRE
// =============
.wrapper__bg_flags {
  position: relative;
  .bgflag {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  .contain_avatar {
    position: relative;

    .box_avatar {
      background: var(--primary_color);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        transform: scale(1.1);
      }
    }
  }
}
//BANDERA DEFAULT
.default {
  background: #ededed;
}

//LGBT
.i {
  background: linear-gradient(
    to right,
    #e20002,
    #fd8b00,
    #fdec00,
    #008024,
    #004ffa,
    #a520bd
  );
}
//TRANSEXUAL
.ii {
  background: linear-gradient(
    to right,
    #35cffb,
    #faabb7,
    #faabb7,
    white,
    #faabb7,
    #faabb7,
    #35cffb
  );
}
//LESBIANA
.iii {
  background: linear-gradient(
    to right,
    #a50160,
    #d063a6,
    #d063a6,
    #e9eeec,
    #e3abce,
    #c64d53,
    #c64d53,
    #8a1e02
  );
}
//BIXESUAL
.iv {
  background: linear-gradient(to right, #cc2773, #7a5197, #7a5197, #14379f);
}
//PANSEXUAL
.v {
  background: linear-gradient(to right, #ff208b, #ffd700, #ffd700, #1fb1fe);
}
//ASEXUAL
.vi {
  background: linear-gradient(
    to right,
    #010101,
    #7e7e7e,
    #ffffff,
    #720072,
    #720072
  );
}
//HETEROSEXUAL --- ALIADO
.vii {
  background: rgb(226, 0, 2);
  background: linear-gradient(
    338deg,
    // rgba(226, 0, 2, 1) 0%,
    rgba(226, 0, 2, 1) 15%,
    // rgba(253, 139, 0, 1) 15%,
    rgba(253, 139, 0, 1) 30%,
    // rgba(253, 236, 0, 1) 30%,
    rgba(253, 236, 0, 1) 45%,
    // rgba(17, 17, 17, 1) 45%,
    rgba(17, 17, 17, 1) 60%,
    // rgba(0, 79, 250, 1) 60%,
    rgba(0, 79, 250, 1) 75%,
    // rgba(0, 128, 36, 1) 75%,
    rgba(0, 128, 36, 1) 90%,
    // rgba(165, 32, 189, 1) 90%,
    rgba(165, 32, 189, 1) 100%
  );
}
//OSO
.viii {
  background: linear-gradient(
    to right,
    #5c3a12,
    #db6b2b,
    #db6b2b,
    #f8df76,
    #f8df76,
    #ffffff,
    #ffffff,
    #555555
  );
}
//NO BINARY
.ix {
  background: linear-gradient(
    to right,
    #ecde14,
    #ecde14,
    #ffffff,
    #8e4ac2,
    #8e4ac2,
    #000000
  );
}
//HOMOSEXUAL
.x {
  background: linear-gradient(
    to right,
    rgba(55, 122, 106, 1) 0%,
    rgba(88, 157, 159, 1) 20%,
    rgba(91, 161, 164, 1) 22%,
    rgba(121, 198, 208, 1) 35%,
    rgba(241, 238, 254, 1) 51%,
    rgba(134, 171, 223, 1) 62%,
    rgba(61, 128, 198, 1) 81%,
    rgba(21, 50, 108, 1) 100%
  );
}
.xi {
  background: rgb(183,43,124);
  background: linear-gradient(6deg, rgba(183,43,124,1) 42%, rgba(255,152,174,1) 58%);
}
//OTHER
.other {
  background: var(--primary_color);
}

// ==========

.box_avatar_user {
  &.flags_box {
    position: relative;
    .flags_bg {
      width: 125px;
      min-width: 125px;
      height: 125px;
      top: 0;
      left: 0;
      position: absolute;
      border-radius: 50%;
      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
    }
  }
  .avatar {
    background: $white_text;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    border-radius: 50%;
    & > img {
      position: relative;
      height: 100%;
      border-radius: 50%;
      background: #fff;
      transform: scale(1.3);
    }
  }
}
// ==========

.box_empty {
  text-align: center;
  color: #666666;
  span {
    font-size: 2.5rem;
  }
}

.card_tabs {
  background: $white_text;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-radius: 0 5px 5px 5px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  padding: 1rem 1.5rem;
}

.drawer_btns_sect {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// === WRAPPER MAIN ===
.wrapper_main {
  margin: 0 auto;
  max-width: 1500px;
  .section_main {
    @include bggrey;
    width: calc(100% - 215px);
    margin-left: 215px;
    margin-top: 4rem;
  }
}

// === WRAPPER HOME ===
.wrapper_home {
  .row_data_home {
    margin-bottom: 1rem;
    .link_data_home {
      display: block;
      height: 100%;
      margin-bottom: 1rem;
      .item_data_home {
        background: $white_text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem;
        border-radius: $border_radius;
        height: 100%;
        min-height: 120px;
        width: 100%;
        .slick-slider {
          .slick-dots {
            bottom: -10px;
          }
        }
        h5,
        h2 {
          line-height: 1.1;
        }
        .label_data {
          font-size: 0.85rem;
          margin-bottom: 0;
        }
        .rewards {
          line-height: 0.8;
        }
        .box_company_home {
          width: calc(100% - 70px);
          position: relative;
          img {
            width: 80%;
          }
        }
        .drop_icon {
          background: var(--primary_color);
          width: 60px;
          min-width: 60px;
          margin-left: 0.5rem;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          img {
            width: 55%;
          }
        }
      }
    }
  }
  .row_drops {
    margin-top: 1.5rem;
    .drop_home {
      background: $white_text;
      padding: 0.75rem 1rem;
    }
    .ttls_drop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .text {
        margin: 0;
        width: 100%;
        font-weight: 600;
        color: var(--primary_color);
      }
      .more {
        min-width: 70px;
        margin-left: 1rem;
      }
    }
    .content_drop {
      min-height: 270px;
      max-height: 270px;
      overflow: auto;
      padding-right: 0.5rem;
      @include scrollbar_vertical;
    }
    .box_schedule {
      position: relative;
      .span_month {
        position: absolute;
        top: 1rem;
        right: 0.75rem;
        font-size: 0.9rem;
        font-family: "is_medium";
      }
    }
    .box_business {
      img {
        width: 100%;
      }
    }
  }

  .for_sec_slick {
    .sec_slick {
      .slick-list {
        padding: 10px 60px 20px !important;
        height: auto !important;
        .wrapper_box_stories {
          background: #ffffff;
          margin: 0 0.75rem;
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
          color: #333;
          .ttls_in_stories {
            display: none;
          }
          .section_item_stories {
            text-shadow: none;
            .box_info_stories {
              background: transparent;
              padding: 0;
              border-radius: 0;
              .paragraph {
                height: 75px;
              }
            }
            .more a {
              color: #333;
              font-weight: normal;
            }
          }
        }
      }
      /* Arrows */
      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        background: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 15px;
        display: block;
        width: 25px;
        height: 25px;
        padding: 0;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 9;
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        opacity: 0.6;
      }

      .slick-prev {
        right: 45px;
        border-radius: 0.25rem 0 0 0.25rem;
      }
      .slick-next {
        right: 15px;
        border-radius: 0 0.25rem 0.25rem 0;
      }

      .slick-prev:hover,
      .slick-prev:focus,
      .slick-next:hover,
      .slick-next:focus {
        opacity: 1;
        outline: none;
        background: var(--primary_color);
      }
      .slick-prev:focus,
      .slick-prev:hover {
        &::before {
          border-right: 8px solid #ffffff;
        }
      }
      .slick-next:focus,
      .slick-next:hover {
        &::before {
          border-left: 8px solid #ffffff;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        content: "";
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        z-index: 9;
      }

      .slick-prev:before {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 8px solid #444444;
        border-bottom: 5px solid transparent;
      }

      .slick-next:before {
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #444444;
        border-bottom: 5px solid transparent;
      }
    }
  }
}

.info_post {
  h6 {
    word-break: break-word;
  }
}

.item_cards_directory {
  background: #f1f1f1;
  padding: 0.5rem;
  border-radius: 8px;
}

// === SEC CANCELACION ===
.wrapping_pay_canceled {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  .box_cancel {
    padding-top: 2rem;
    .cancel_label {
      font-family: "is_bold";
      font-size: 0.9rem;
      letter-spacing: 3px;
      color: #ccc;
    }
    .cancel_box_icons {
      font-size: 6rem;
      color: #e00c0c;
      padding-top: 1rem;
      & > h1 {
        font-family: "is_bold";
        font-size: 3.5rem;
        font-style: italic;
        line-height: 0.75;
      }
    }
    .cancel_info {
      margin-top: 2rem;
      padding: 0 2rem;
    }
  }

  .box_new_try {
    padding-top: 2rem;

    .new_try {
      padding: 2rem 0;

      &:first-child {
        border-bottom: 1px solid #ccc;
      }
    }
  }
}

// === SEC SUCCESS ===
.wrapper_pay_successful {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  .box_success {
    padding: 2rem 0;
  }

  .box_recomp {
    padding: 1rem 2rem;
    border-left: 1px solid #ccc;
  }
}

.wrapper_editor_textual {
  .wrapper-class {
    width: 100%;
    .editor-class {
      border: 1px solid #efefef;
      height: auto !important;
      min-height: 200px;
      border-radius: 2px;
      padding: 0 1rem;
    }
    .rdw-option-active {
      box-shadow: 1px 1px 0px #bfbdbd inset;
      background: #dadada !important;
    }
    .public-DraftEditor-content {
      min-height: 200px;
    }
    .rdw-justify-aligned-block > div {
      width: 100%;
      span {
        white-space: normal !important;
      }
    }
    .public-DraftEditor-content[contenteditable="true"] {
      -webkit-user-modify: read-write !important;
    }
    .DraftEditor-root {
      * {
        white-space: normal !important;
      }
    }
  }
}

.fade {
  opacity: 1;
}

.textarea-box {
  height: auto !important;
}

.wrapper_modal_cap {
  max-width: 100%;
  margin: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    height: 90%;
    width: 90%;
    background: #000000;
    & > div {
      height: 100%;
      .toolbar_opt_modal {
        position: absolute;
        top: -30px;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btns_modal {
          background: #ffffff;
          box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.95);
          margin-left: 0.5rem;
          padding: 0.25rem 0.4rem;
          font-size: 0.75rem;
          letter-spacing: 1px;
          border-radius: 0.2rem;
          font-weight: 500;
          cursor: pointer;
          color: #111;
          &:hover {
            color: #c90f0f;
          }
        }
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 0.25rem;
  }
  video {
    width: 100%;
    height: 100%;
  }
}

.wrapper_social_links {
  display: flex;
  align-items: center;
  gap: 5px;
  .a_ico_social {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 50%;
    font-size: 1.2rem;
    &:hover,
    &:focus,
    &:active {
      color: var(--primary_color);
      background: #ededed;
    }
  }
}

// === REACT SLICK SLIDE ===
.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  width: 100%;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 0;
  height: 0;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #333333;
  width: 8px;
  height: 8px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: black;
  background: var(--primary_color);
}

.divtop {
  .box_ttl_opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .ttls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    span {
      background: $bg_grey;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      background: $black;
      z-index: 0;
    }
    .ico_shop {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      min-width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 2px solid $black;
      .fas {
        font-size: 1rem;
      }
    }
  }
}

.wrapper_surveys .aside_lateral {
  padding-bottom: 33px;
}

.box_img_stories.left_image {
  width: 100%;
  height: 164px;
}

.boxImage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.boxImage.icon > * {
  background-color: #dfdfdf;
  vertical-align: middle;
  height: 100%;
}

.boxImage p {
  font-size: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.drawer_reg_sex {
  border: 2px dotted var(--primary_color);
  font-size: 0.8rem;
  font-style: italic;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  color: var(--primary_color);
  line-height: 1.2;
  margin-top: 0.25rem;
  width: fit-content;
}

.box_content_img .dots_notif {
  padding: 2px 5px;
  background: #fff;
  color: #333;
  position: absolute;
  top: 16px;
  left: 1.2em;
  display: flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border-radius: 10px;
  font-size: 0.75rem;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.rating {
  line-height: 1;
  display: inline-block;

  &__item {
    display: inline;
    font-size: 0;

    &::before {
      font-size: 18px;
      content: "★";
      color: lightgray;
    }
  }

  &__input {
    position: absolute;
    visibility: hidden;
  }
}

.rating__done {
  justify-content: flex-end;
  &::before {
    font-size: 18px;
    content: "★";
    color: lightgray;
  }
}

.rating:hover .rating__item::before {
  color: var(--primary_color);
}

.rating__item:hover ~ .rating__item::before {
  color: lightgray;
}

.rating__item--selected::before {
  color: var(--primary_color);
}

.rating__itemdone--selected::before {
  color: #f8df76;
}

.Tooltip {
  display: inline-block;
}

.Tooltip-content {
  position: absolute;
  word-wrap: break-word;
  min-width: 230px;
  max-width: 400px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 0.9375em;
  line-height: 1.4em;
  padding: 10px;
  z-index: 2;
  text-align: left;
  color: #555;
}
.Tooltip-content.bottom:after {
  top: -12px;
  border-bottom-color: white;
  border-top: none;
}
.Tooltip-content.bottom:before {
  top: -14px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-top: none;
}
.Tooltip-content.bottom.right:after,
.Tooltip-content.bottom.right:before {
  left: 32px;
}
.Tooltip-content:after,
.Tooltip-content:before {
  content: "";
  position: absolute;
  margin-left: -7px;
  border: 14px solid transparent;
}

.help-tip {
  display: inline-block;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  line-height: 18px;
  font-size: 11px;
  background: #fafefe;
  border: 1px solid #a3aaaa;
  text-align: center;
  color: #7a7e7e;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  cursor: default;
}

.help-tip:hover {
  color: #5a5e5e;
  background: #eaeeee;
  border-color: #9a9999;
}
.bg-mycolor {
  background-color: var(--primary_color);
}
.progress {
  height: 10px;
  margin-top: 5px;
}
.itemcat {
  background: var(--primary_color);
}

.name_directory {
  min-height: 35px;
}

.name_directory_des {
  text-overflow: ellipsis;
}

// === RWD ===

@media screen and (max-width: 1200px) {
  .wrapper_home {
    .row_data_home .link_data_home {
      flex: 0 0 50%;
      max-width: 50%;
      &:last-child {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item_data_home {
        .slick-slider {
          .slick-slide {
            padding-right: 1rem;
          }
        }
      }
    }
  }
  .rewards {
    overflow-wrap: break-word;
  }
  .wrapper_surveys {
    .content_surveys {
      width: calc(100%);
      .box_more_survey {
        .btn_ellipsis {
          display: flex;
        }
      }
    }
    .aside_lateral {
      position: fixed;
      top: 65px;
      right: -337px;
      width: 100%;
      max-width: 335px;
      padding-bottom: 30px;
      box-shadow: -5px 0 12px rgba(0, 0, 0, 0.15);
      .btn_ellipsis_close {
        display: block;
      }
    }
  }
  .box_container_hub {
    .item_provaider_hub {
      .item_content {
        height: 100%;
        .box_p_img {
          min-height: auto;
          height: 100%;
        }
      }
    }
  }
  .wrapper_help {
    .content_ttls {
      .box_img_surveys img {
        width: 85%;
      }
    }
  }
  .wrapper_our_company {
    .sec_list_post {
      .box_ttl_opt {
        .btn_ellipsis {
          display: flex;
        }
      }
    }
    .sec_aside_our_company {
      display: none;
      position: fixed;
      top: 4rem;
      right: 0;
      box-shadow: -3px 0 20px rgba(0, 0, 0, 0.1);
      max-width: 315px;
      width: 100%;
      &.is-show {
        display: block;
      }
      .closex {
        display: block;
      }
    }
  }
  .wrapper_our_company
    .sec_post
    .content_post
    .sec_controls_post
    .content_options_post
    .box_actions_post {
    padding-bottom: 3rem;
  }
}
@media screen and (max-width: 1160px) {
  .wrapper_jobbank .sec_category_jobs {
    max-width: 200px;
  }
}
@media screen and (max-width: 1080px) {
  .wrapper_pending {
    max-width: 319px;
    top: 65px;
    right: -320px;
    z-index: 9;
    position: fixed;
    &.is-show {
      right: 0;
    }
    .card {
      height: calc(100vh - 65px);
      border-radius: 0;
    }
  }
  .wrapper_profile {
    .content_profile {
      .box_main_info_profile .btn_ellipsis {
        display: flex;
      }
    }
    .closeAll {
      display: block;
    }
  }
  .wrapper_our_company {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .nav-tabs {
      border-bottom: 1px solid #666666;
      justify-content: space-around;
      .nav-item {
        margin: 0;
        width: 100%;
        .nav-link {
          min-width: auto !important;
          width: 90%;
          margin: 0 auto;
          text-align: center;
          cursor: pointer;
          background: transparent;
          border: 0;
          &.active {
            font-family: "is_medium";
            color: #333333;
            border-bottom: 5px solid var(--primary_color);
          }
        }
      }
      .tab-content {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
    }
    .sec_list_post {
      width: 100%;
      max-width: 290px;
      height: calc(100vh - 4rem);
      overflow: auto;
      @include scrollbar_vertical;
      .box_ttl_opt {
        position: relative;
        .btn_ellipsis {
          display: flex;
        }
      }
      .divtop {
        padding: 0 1.25rem;
        padding-top: 2rem;
      }
      .box_ttls_sec {
        margin: 0 1.25rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 1.25rem 0.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $white_text;
        &.article {
          background: #4282f5;
          .is_mes {
            text-transform: capitalize;
          }
        }
        &.gallery {
          background: #e76e0a;
          .ico_box_sec {
            color: #e76e0a;
          }
        }
        .ico_box_sec {
          background: $white_text;
          color: #4282f5;
          min-width: 50px;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          margin-right: 0.5rem;
          text-transform: uppercase;
          font-family: "is_bold";
          font-size: 2rem;
        }

        p {
          font-size: 0.75rem;
        }
      }
      .content_list_post {
        hr {
          margin: 0.5rem 0;
        }
        .item_list_post {
          background: $white_text !important;
          padding: 1.25rem 1rem;
          position: relative;
          cursor: pointer;
          border-bottom: 1px solid #dedede;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
          & > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          &:hover {
            &.is_read {
              border-radius: 0.3rem 0.3rem 0 0 !important;
              color: $black;
              opacity: 1;
            }
          }
          &.is_read {
            background: transparent !important;
            color: #666666;
            box-shadow: none;
            border-radius: 0 !important;
            opacity: 0.9;
          }
          .date_post {
            font-size: 0.75rem;
            color: #999999;
          }
          .views_post {
            position: absolute;
            top: 0.35rem;
            right: 1rem;
            color: #999999;
            font-size: 0.7rem;
          }
        }
      }
    }

    .sec_post {
      width: 100%;
      height: calc(100vh - 4rem);
      position: relative;
      .back_ico {
        display: none;
        background: $white_text;
        padding: 0.25rem 0.35rem;
        border-radius: 5px;
        position: absolute;
        top: 10px;
        left: 25px;
        color: #666666;
        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
      }
      .content_post {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content_info_post {
          padding: 2rem;
          width: 100%;
          overflow: auto;
          height: 100%;
          @include scrollbar_vertical;
          .info_post {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0.5rem 0 2rem;
          }
        }
        .sec_controls_post {
          width: 100%;
          height: auto;
          background: $white_text;
          box-shadow: 2px -2px 9px rgba(0, 0, 0, 0.1);
          .content_options_post {
            .box_files_post {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.75rem;
              border-bottom: 1px solid #d9d9d9;
              .likes {
                min-width: 50px;
                .like_heart {
                  font-size: 1.2rem;
                  margin-right: 0.25rem;
                  color: #da2222;
                }
              }
            }
            // .box_actions_post {
            //   font-size: 0.9rem;
            //   display: flex;
            //   justify-content: space-between;
            //   align-items: center;
            //   padding: 1.25rem 0.75rem;
            //   .box_tag_read {
            //     min-width: 110px;
            //     .tag_read {
            //       padding: 0.25rem 0.35rem;
            //       border-radius: 5px;
            //       background: #4282f5;
            //       color: #ffffff;
            //       cursor: pointer;
            //       &:hover {
            //         background: #2f6fe6;
            //       }
            //     }
            //   }
            //   .box_btns_post {
            //     width: 100%;
            //     text-align: right;
            //     display: flex;
            //     justify-content: flex-end;
            //     align-items: center;
            //     .btn_action {
            //       padding: 0.25rem 0.35rem;
            //       border-radius: 5px;
            //       display: flex;
            //       justify-content: flex-start;
            //       align-items: center;
            //       cursor: pointer;
            //       margin-left: 0.5rem;
            //       &:hover {
            //         background: $bg_grey;
            //       }
            //       & > i {
            //         margin-right: 0.35rem;
            //         font-size: 1.35rem;
            //         &.far {
            //           color: $black;
            //         }
            //         &.fas {
            //           color: #da2222;
            //         }
            //       }
            //     }
            //   }
            // }
          }
        }
      }
      .ReactGridGallery {
        .ReactGridGallery_tile {
          width: calc(48% - 5px);
          .ReactGridGallery_tile-viewport {
            height: 135px !important;
            img {
              height: 135px !important;
            }
          }
        }
      }
    }
  }
  .wrapper_jobbank {
    .contain_gral_jobbank {
      flex-direction: column-reverse;
      .sec_category_jobs {
        min-width: 100%;
        .text {
          font-size: 0.95rem;
          margin-bottom: 0.5rem !important;
        }
        .box_items_aside {
          flex-wrap: nowrap;
          overflow: auto;
          padding-bottom: 0.25rem;
          .tags_item_category_jobs {
            min-width: fit-content;
          }
        }
      }
    }
  }
  .wrapper_directory {
    .box_filter_messg {
      flex-wrap: wrap;
      .box_ordering {
        width: 45%;
        margin-bottom: 0.25rem;
      }
    }
  }
  .name_directory {
    min-height: 65px;
  }
}
@media screen and (max-width: 992px) {
  .wrapper_slide_home {
    margin-bottom: 1rem;
  }
  .rewards {
    overflow-wrap: break-word;
    max-width: 100%;
  }
  .wrapper_surveys .content_surveys .content_ttls {
    padding: 6rem 1rem;
  }
  .box_container_hub {
    .item_provaider_hub {
      width: 33.3%;
      padding: 0.5rem;
    }
  }
  .surveys {
    .ico_left {
      display: none;
    }
    .ico_up {
      display: inline-block;
    }
  }
  .wrapper_category {
    .box_category_items {
      .box_item_cupon {
        width: 33.3%;
      }
    }
  }
  .wrapper_galleries {
    .box_imgs_gallery {
      .item_img_gallery {
        width: 25%;
      }
    }
  }
  .wrapper_help {
    .content_ttls {
      .box_img_surveys img {
        width: 100%;
      }
    }
  }
  .boxsearch_item {
    margin-bottom: 1rem;
    &:last-child {
      max-width: 100%;
      flex: 100%;
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 900px) {
  .drawer_btns_sect {
    align-items: flex-start;
    flex-direction: column;
  }
}
@media screen and (max-width: 860px) {
  .wrapper_our_company {
    .sec_list_post {
      max-width: 100%;
      min-height: calc(100vh - 4rem);
      height: 100%;
    }
    .sec_post {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      background: $white_text;
      &.is-show {
        display: block;
      }
      .back_ico {
        display: block;
      }
      .content_post {
        .content_info_post {
          padding-top: 1rem;
          .info_post {
            margin-top: 2.25rem;
          }
        }
      }
      .ReactGridGallery {
        .ReactGridGallery_tile {
          width: calc(23% - 5px);
          .ReactGridGallery_tile-viewport {
            height: 135px !important;
            img {
              height: 135px !important;
            }
          }
        }
      }
    }
  }
  .sec_ttls_jobbank {
    .content_ttls_jobbank {
      align-items: flex-start;
      flex-direction: column;
      .content_btns_job {
        margin: 0.5rem 0 1.5rem;
        .btn {
          margin: 0;
          margin-right: 0.5rem;
        }
      }
    }
    .content_ttls_line {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .wrapper_menu_aside {
    width: 275px;
    margin-left: -280px;
    box-shadow: 5px 0 16px rgba(0, 0, 0, 0.2);
    &::after {
      margin-left: -280px;
    }
  }
  .wrapper_main {
    .section_main {
      width: 100%;
      margin-left: 0;
      margin-top: 3rem;
      padding: 1rem 0.5rem;
      padding-bottom: 0;
    }
  }
  .wrapper_header {
    width: 100%;
    .box_brand .btn_menu {
      display: inline-block;
    }
  }
  .wrapper_login {
    .content_login {
      .box_msj {
        width: 50%;
      }
    }
  }
  .padd {
    padding: 1.5rem 1rem;
  }
  .wrapper_register {
    .bg_register {
      top: -20px;
    }
    .container_register {
      padding-top: 120px;
    }
  }
  .wrapper_chats {
    .wrapper_contacts {
      .box_item_chat {
        &:hover {
          .info_item_chat {
            .options {
              .opt_chat {
                background: #d2d5db;
                &:hover {
                  color: var(--primary_color);
                }
              }
            }
          }
        }
        .info_item_chat {
          .options {
            display: flex;
            .opt_chat {
              background: #e6ebf5;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .wrapper_header {
    .content_header {
      .box_search_input {
        display: none;
      }
    }
  }
  .wrapper_home {
    .row_data_home {
      .link_data_home {
        .item_data_home {
          .slick-slider {
            .slick-slide {
              padding-right: 0;
            }
          }
          h5,
          h2 {
            line-height: 1;
          }
          .label_data {
            font-size: 0.85rem;
            margin-bottom: 0;
          }
          .rewards {
            line-height: 0.8;
          }
          .box_company_home {
            width: calc(100% - 70px);
            position: relative;
            img {
              width: 80%;
            }
          }
          .drop_icon {
            background: var(--primary_color);
            width: 60px;
            min-width: 60px;
            margin-left: 0.5rem;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            img {
              width: 55%;
            }
          }
        }
      }
    }
  }
  .wrapper_login {
    .content_login {
      flex-direction: column;
      height: 100%;
      min-height: 625px;
      .box_msj {
        width: 100%;
        border-radius: 8px 8px 0 0;
        height: auto;
        padding: 1.25rem 1rem;
      }
      .box_form {
        width: 100%;
        padding-bottom: 2rem;
      }
    }
  }

  .wrapper_surveys .content_surveys .content_ttls {
    padding: 3rem 1rem;
    .box_img_surveys {
      display: none;
    }
  }
  .box_container_hub {
    .item_provaider_hub {
      .item_content {
        .box_p_img {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .divtop {
    .box_ttl_opt {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      flex-direction: column-reverse;
      .wrapper_tool_discount {
        margin-bottom: 1.5rem;
      }
      &.no_reverse {
        flex-direction: column !important;
        .box_actions {
          margin-top: 1rem;
        }
      }
    }
  }
  .wrapper_directory {
    .divtop .box_ttl_opt {
      flex-direction: row;
    }
  }
  .wrapper_tool_discount {
    min-width: 100%;
  }
  .wrapper_category {
    padding-top: 0 !important;
    .box_tag_category {
      .item_tab_category {
        .tag_ico {
          font-size: 1.65rem;
        }
        .tag_name {
          font-size: 0.75rem;
        }
      }
    }
  }
  .wrapper_list_history {
    .list_history {
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      .item_list_history {
        width: 50%;
        margin-bottom: 0.5rem;
        &:first-child {
          width: 100%;
        }
      }
    }
  }
  .wrapper_profile {
    .content_profile {
      .box_main_info_profile {
        .box_user_info {
          .second_info {
            flex-wrap: wrap;
            justify-content: flex-start;
            & > div {
              width: 33%;
              border-right: 1px solid #ccc !important;
            }
          }
        }
      }
    }
  }
  .wrapper_help {
    .content_tabs_help {
      margin-top: 2rem;
    }
    .content_ttls {
      height: 100%;
      padding: 0.5rem 1rem;
      .box_img_surveys img {
        display: none;
      }
    }
  }
  .wrapper_our_company {
    .sec_post {
      .ReactGridGallery {
        .ReactGridGallery_tile {
          width: calc(30% - 5px);
          .ReactGridGallery_tile-viewport {
            height: 115px !important;
            img {
              height: 115px !important;
            }
          }
        }
      }
    }
  }
  .wrapper_directory {
    .divtop {
      .ttls {
        align-items: flex-end;
        position: relative;
        flex-direction: column-reverse;
        gap: 10px;
        &::after {
          display: none;
        }
      }
    }
  }

  .section_surveys {
    padding: 0;
    .slick-list {
      height: 300px;
    }
    .box_ttl_opt h2 {
      font-size: 1.5rem;
    }
  }
  .drawer__cont_svg {
    height: 300px !important;
    width: 100% !important;
    svg {
      width: 100% !important;
    }
    * {
      height: 300px !important;
    }
  }
}
@media screen and (max-width: 575px) {
  .name_directory,
  .name__categ,
  .box_content_img {
    max-height: 100%;
    min-height: 100%;
  }
  .wrapper_details_directory {
    .drawer_content {
      .content_picture {
        max-width: 125px;
        img {
          border-radius: 5px;
        }
      }
    }
  }
  .ttls_black {
    font-size: 2rem;
  }
  .wrapper_login {
    .content_login {
      .box_form {
        .box_logo_company_login {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .wrapper_home .row_data_home .link_data_home {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .padd {
    padding: 0.5rem;
    padding-bottom: 1.5rem;
  }
  .wrapper_purchased_item {
    .head_purchased_item {
      .col_unicas {
        flex-wrap: wrap;
        .cols {
          width: 50%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .wrapper_category {
    .box_tag_category {
      .item_tab_category {
        .tag_ico {
          font-size: 1.65rem;
          margin-bottom: 0;
          padding: 0.5rem;
        }
        .tag_name {
          font-size: 0.75rem;
          display: none;
        }
      }
    }
  }
  .wrapper_board {
    .content_text_board {
      max-height: 100%;
      color: $black;
      background: $white_text;
      & > div {
        .item_cat_board {
          h5 {
            color: var(--primary_color);
          }
        }
      }
    }
    .box_content_board {
      display: none;
      background: $bg_grey;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      z-index: 9;
      padding-top: 5.5rem;
      &.is-show {
        display: block;
      }
      .back_ico {
        display: block;
      }
    }
  }
  .wrapper_help {
    .content_tabs_help {
      .nav.nav-pills {
        flex-direction: row !important;
        .nav-item {
          width: 50%;
          padding: 0 0.5rem;
          .nav-link {
            min-height: 100px;
          }
        }
      }
    }
  }
  .my_modal .modal-body {
    max-height: 70vh;
  }
  .wrapper_detailsjobs
    .box_items_jobs
    .item_card_jobs
    .card_options
    .box_btns_actions_item {
    width: 70%;
  }

  .box_img_stories.left_image {
    width: 100%;
    height: 260px !important;
  }
}
@media screen and (max-width: 480px) {
  .wrapper_galleries {
    .box_imgs_gallery {
      .item_img_gallery {
        width: 33.3%;
      }
    }
  }
  .wrapper_header {
    .box_notifications {
      .btn_notifications {
        .icon_bell_label {
          display: none;
        }
      }
    }
  }
  .sec_doodle {
    width: 250px;
    height: 65px;
    position: relative;
    margin-left: 0.25rem;
    display: block;
    .info_doodle {
      h6 {
        color: #bf3c99;
      }
    }
    &:hover {
      .info_doodle {
        h6 {
          color: #bf3c99;
        }
        p {
          color: var(--primary_color);
        }
      }
    }
    &.rwd__movil {
      display: none;
    }
    &.rwd__hide {
      display: block;
    }
  }
  .wrapper_login {
    padding: 0;
    .content_login {
      min-height: 600px;
      box-shadow: none;
      .box_msj {
        border-radius: 0 !important;
      }
      .box_form {
        padding: 0.5rem 1.25rem;
        .box_btn_login {
          margin-bottom: 0;
        }
      }
    }
  }

  .box_container_hub {
    .item_provaider_hub {
      width: 50%;
      .item_content {
        .box_p_img {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .divtop .ttls {
    &::after {
      display: none;
    }
    span {
      background: transparent;
    }
  }
  .btn {
    margin-bottom: 0.5rem;
  }
  .wrapper_head_rewards {
    .content_statistics {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 0;
      flex-wrap: wrap;
      .item_statistics {
        width: 45% !important;
        margin: 0;
        margin-bottom: 1rem;
        &.large {
          width: 100% !important;
        }
      }
    }
  }
  .wrapper_category {
    .box_category_items {
      .box_item_cupon {
        width: 50%;
      }
    }
  }
  .card_tabs {
    padding: 0.75rem;
  }
  .wrapper_help {
    .tab-content {
      .content_info_help {
        .ttl_info_help {
          span {
            font-size: 2rem;
          }
          h5 {
            font-size: 1.45rem;
          }
        }
      }
    }
  }
  .wrapper_audience {
    .content_card_audience {
      padding: 1rem;
      .box_list_audience {
        .item_audience {
          .box_info_audience {
            .box_icon {
              min-width: 45px;
              font-size: 2.25rem;
            }
            h5 {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
  .wrapper_register {
    .container_register {
      .drawer_card_register {
        padding: 0.85rem 1.1rem 1.5rem;
      }
    }
  }
  .wrapper_jobbank {
    .sec_ttls_jobbank {
      .content_ttls_jobbank {
        .content_btns_job {
          min-width: 100%;
          .btn {
            margin-bottom: 0.75rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 460px) {
  .wrapper_details_directory {
    .card {
      padding: 0;
      .drawer_content {
        flex-direction: column-reverse;
        .content_picture {
          max-width: 100%;
          img {
            max-height: 150px;
          }
        }
        .conten_info {
          .card-title {
            font-size: 1.25rem;
          }
          .box_data {
            margin-top: 1rem;
          }
        }
      }
    }
    .wrapper_ranking {
      flex-direction: column;
    }
  }
  .wrapper_profile {
    .content_profile {
      .box_main_info_profile {
        .box_user_info {
          .main_info {
            justify-content: flex-start;
            flex-wrap: wrap;
            .box_one,
            .box_two {
              width: 100%;
            }
            .box_one {
              display: block;
              text-align: center;
              .ico_save {
                bottom: auto;
                top: 112px;
                left: 48%;
              }
              .custom-file-upload {
                bottom: auto;
                top: 110px;
                left: 58%;
              }
              .wrapper__bg_flags {
                max-width: 150px;
                margin: 0 auto;
              }
              .profile_info {
                margin: 0%;
                margin-top: 2rem;
              }
            }
            .box_two {
              margin-top: 0.5rem;
            }
          }
          .second_info {
            flex-wrap: wrap;
            justify-content: flex-start;
            & > div {
              width: 50%;
              border-right: 1px solid #ccc !important;
            }
          }
        }
      }
    }
  }
  .wrapper_our_company {
    .sec_post {
      .back_ico {
        left: 15px;
      }
      .content_post {
        .content_info_post {
          padding: 1rem;
        }
      }
      .ReactGridGallery {
        .ReactGridGallery_tile {
          width: calc(47% - 5px);
          .ReactGridGallery_tile-viewport {
            height: 115px !important;
            img {
              height: 115px !important;
            }
          }
        }
      }
    }
  }
  .wrapper_help {
    .content_tabs_help {
      .nav.nav-pills {
        .nav-item {
          width: 50%;
          padding: 0 0.5rem;
          .nav-link {
            min-height: 120px;
            flex-direction: column;
            text-align: center;
            padding: 0.15rem;
            padding-top: 1.5rem;
            span {
              font-size: 1.8rem;
            }
            h5 {
              padding: 0;
              font-size: 1rem;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
  .wrapper_audience {
    .content_card_audience {
      padding: 1rem;
      .box_list_audience {
        .item_audience {
          flex-direction: column;
          align-items: flex-start;
          .box_info_audience {
            .box_icon {
              min-width: 45px;
              font-size: 2.25rem;
            }
            h5 {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
  .content_ttls {
    padding: 1rem !important;
    height: 100% !important;
  }
  .wrapper_course {
    .content_card_audience {
      padding: 0.75rem;
      .box_list_audience {
        .item_audience {
          padding: 0;
          .box_info_audience {
            padding-right: 0.25rem;
            .box_icon {
              min-width: 45px;
              font-size: 2.25rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .wrapper_register {
    .container_register {
      .drawer_card_register {
        .box_brand_register {
          img {
            max-height: 65px;
          }
        }
        .box_form_register {
          .box_form_btns {
            .btn {
              min-width: auto;
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }
  .wrapper_directory {
    .box_ttl_opt {
      .wrp_filter_directory {
        margin-top: 1rem;
        .btn {
          margin-left: 0;
          min-width: 100%;
        }
      }
    }
  }
  .wrapper_reg_services
    .content_services
    .wrapper_form
    .content_shadows_form
    .box_pay_sec {
    flex-direction: column-reverse;
  }
  .wrapper_our_company
    .sec_post
    .content_post
    .sec_controls_post
    .content_options_post
    .box_comments_box
    .contain_comments
    .wrapper__items_comments
    .answer__in_comment {
    margin-right: 0;
  }
  .wrapper_calendar {
    .rbc-calendar {
      .rbc-toolbar {
        position: relative;
        .rbc-toolbar-label {
          font-size: 1.5rem;
        }
      }
    }
  }
}
@media screen and (max-width: 415px) {
  .wrapper_header .box_brand img {
    max-height: 40px;
  }

  .wrapper_register {
    .container_register {
      .drawer_card_register {
        .box_form_register {
          .box_form_btns {
            .btn {
              width: 100%;
              position: relative;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
  .wrapper_menu_aside {
    padding: 1.25rem 1rem;
  }
  .wrapper_chats {
    max-width: 100%;
    &.open {
      right: 0;
      bottom: 0;
      max-height: 100%;
      .sec_ttl_chat {
        .ttl_chats {
          height: 50px;
          border-radius: 0;
          .btn_expand {
            top: 0px;
            right: 0;
            border-radius: 0;
            height: 50px;
            width: 45px;
          }
        }
      }
      .sec_content_chats {
        .content_navbar_chat {
          width: 0;
          margin-left: -100%;
          &.show-navbar {
            margin: 0;
          }
          .navbar_chat {
            .item_navbar {
              height: 55px;
              font-size: 1.25rem;
            }
          }
        }
        .content_sec_chats {
          width: 100%;
          &.show-navbar {
            width: calc(100% - 60px);
          }
        }
      }
    }
    .sec_ttl_chat {
      .ttl_chats {
        width: 170px;
        .part1 {
          display: block;
        }
      }
    }
  }
  .wrapper_list_chats
    .sec_new_group
    .cont_new_group__contact
    .box_btns_options {
    height: 75px;
  }
  .wrapper_chat_conversations .sec_content_typing {
    height: 110px;
  }
  .wrapper_profile
    .content_profile
    .box_main_info_profile
    .box_details_account
    .text-absRight {
    top: 55px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
  }

  .box_items_jobs {
    .item_card_jobs {
      position: relative;
      flex-direction: column;
      .card_info_jobs {
        padding-top: 5rem;
        .name_vacant {
          margin-bottom: 1rem;
        }
      }
      .card_options {
        max-width: 220px;
        margin: 0 auto;
        margin-top: 1rem;
        img {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .wrapper_chats .sec_ttl_chat .ttl_chats .dots_notifications {
    left: 105px;
  }
  .wrapper_chats.open .sec_ttl_chat .ttl_chats {
    .dots_notifications {
      top: 14px;
    }
  }

  .wrapper_detailsjobs {
    .box_items_jobs {
      .item_card_jobs {
        .card_info_jobs {
          padding-top: 80px;
        }
        .card_options {
          img {
            top: 10px;
          }
          .box_btns_actions_item {
            width: 100%;
            top: -55px;
          }
        }
      }
    }
  }

  .wrapper_our_company
    .sec_post
    .content_post
    .sec_controls_post
    .content_options_post
    .box_comments_box
    .contain_comments {
    padding: 1rem 0.6rem;
  }
  .wrapper_our_company
    .sec_post
    .content_post
    .sec_controls_post
    .content_options_post
    .box_comments_box
    .contain_write_comments {
    padding: 1rem 0.6rem 2rem;
  }
}
@media screen and (max-width: 380px) {
  .wrapper_details_directory .drawer_content {
    padding: 0;
  }
  .wrapper_login {
    .content_login {
      .box_form {
        .ttl_form {
          margin-bottom: 1rem;
        }
      }
      .box_msj {
        padding: 1rem;
      }
    }
  }
  .wrapper_box_user_profile {
    .box_data_user {
      flex-direction: column;
      & > div {
        margin-right: 0;
      }
    }
  }
  .wrapping_pay_canceled {
    .box_cancel {
      .cancel_info {
        padding: 0;
      }
    }
    .box_new_try {
      padding-top: 0;
    }
  }
  .wrapper_purchased_item {
    .head_purchased_item {
      .col_unicas {
        flex-wrap: wrap;
        padding: 0;
        .ico_chevron {
          left: auto;
          right: -10px;
          top: 0;
        }
        .cols {
          width: 100%;
          margin-bottom: 0.25rem;
          display: flex;
        }
      }
    }
  }
  .wrapper_category .box_tag_category .item_tab_category {
    cursor: pointer;
    width: 45px;
  }
  .wrapper_calendar {
    overflow: auto;
    min-height: 550px;
    .rbc-header {
      padding: 5px 3px;
      text-transform: capitalize;
    }
    .rbc-calendar {
      .rbc-toolbar {
        min-height: 135px;
        line-height: 1.15;
        .rbc-btn-group {
          bottom: 0;
          button {
            padding: 0.35rem 0.75rem;
          }
          &:first-child {
            left: auto;
            bottom: 2.4rem;
          }
          &:last-child {
            right: auto;
          }
        }
        .rbc-toolbar-label {
          top: 0;
          text-transform: capitalize;
          font-family: "is_medium";
          font-size: 1.5rem;
          padding: 0;
        }
      }
    }
  }
  .wrapper_directory {
    .divtop .box_ttl_opt {
      flex-direction: column;
      .wrp_filter_directory {
        margin-top: 1rem;
        max-width: 100%;
        text-align: center;
      }
    }
  }
  .wrapper_directory .divtop .ttls {
    flex-direction: column;
  }
  .wrapper_reg_services .content_services .wrapper_form .content_shadows_form {
    padding: 1rem;
  }
}
@media screen and (max-width: 360px) {
  .wrapper_galleries {
    .box_imgs_gallery {
      .item_img_gallery {
        width: 50%;
      }
    }
  }
  .col-6 {
    flex: none;
    max-width: 100%;
  }
  .item_purchase .card-header .box_logo_shop .bg_logo_shop {
    width: 48px;
    height: 48px;
  }
  .ttlshop {
    font-size: 1.15rem;
  }
  .wrapper_head_rewards .content_statistics .item_statistics {
    .statistics.unique {
      padding-left: 0;
      font-size: 0.9rem;
      .ico_unique {
        min-width: 50px;
        width: 50px;
        font-size: 2rem;
        height: 50px;
        margin-right: 0.5rem;
      }
    }
  }
  .wrapper_register {
    .container_register {
      .drawer_card_register {
        .box_brand_register {
          flex-direction: column-reverse;
          div {
            margin-right: 0;
            margin-top: 0.5rem;
            text-align: center;
            opacity: .95;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .wrapper_tool_discount .item_link_op {
    padding: 0.5rem;
  }
  .divtop {
    .box_ttl_opt {
      margin-bottom: 0.75rem;
      h2 {
        font-size: 1.6rem;
      }
    }
    .ttls {
      font-size: 1.25rem;
    }
    .pp {
      display: none;
    }
  }
  .wrapper_course .box_img_stories.left_image {
    height: 190px !important;
  }
}
