@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
    font-weight: 800;
  }
  100% {
    transform: scale(1, 1);
  }
}

.wrapper_reg_services {
  padding: 0 1rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
  .content_services {
    max-width: 675px;
    margin: 0 auto;
    .paragraph {
      max-width: 600px;
      margin: 0 auto;
      margin-bottom: 1.25rem;
    }
    .box_animate_text {
      display: flex;
      justify-content: center;
      .drawer_reg_sex {
        animation: zoom-in-zoom-out 1.25s ease infinite;
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
    }
    .bar_step_reg {
      display: flex;
      justify-content: space-between;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 1.75rem;
      // padding: 0 1rem;
      .box_item_step {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        &:first-child {
          width: auto;
          &::after {
            display: none;
          }
        }
        &::after {
          content: "";
          width: 100%;
          height: 2px;
          border-bottom: 3px dashed #dfdfdf;
          position: absolute;
          top: 47%;
          right: 0;
        }
        // &:not(.step_active){
        //   .item_step{
        //     background: cadetblue;
        //   }
        // }
        &.step_active {
          &::after {
            border-bottom: 3px solid var(--primary_color);
          }
          .item_step {
            background: var(--primary_color);
            color: #fff;
            border: 0;
          }
        }
        .item_step {
          background: #fff;
          border: 3px solid #dfdfdf;
          width: 37px;
          height: 37px;
          border-radius: 50%;
          color: #aaa;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "is_bold";
          font-size: 0.9rem;
          position: relative;
          z-index: 1;
        }
      }
    }
    .wrapper_form {
      .content_shadows_form {
        padding: 2rem;
        padding-top: 1rem;
        border-radius: 0.75rem;
        box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.15);
        background: #fff;
        .goback {
          justify-content: flex-end;
          max-width: 100%;
        }
        .form-control-sm {
          height: calc(1.5em + 0.75rem + 2px) !important;
          font-size: 1rem;
        }
        .isHidde {
          overflow: hidden;
        }
        .box_picture {
          .content_pre_picture{
            width: auto;
            background: cornflowerblue;
            display: inline-block;
            position: relative;
            margin-top: .5rem;
            .closex{
              background: red;
              border: 2px solid #fff;
              color: #fff;
              position: absolute;
              top: -8px;
              right: -12px;
              width: 22px;
              height: 22px;
              font-size: .80rem;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              box-shadow: -1px 4px 6px rgba(0, 0, 0, 0.5);
              &:hover{
                background: rgb(207, 17, 55);
              }
            }
            img {
              max-height: 125px;
            }
          }
        }
        .input-group-text {
          min-width: 100px;
        }
        .box_info_save {
          h6 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              max-height: 45px;
            }
          }
        }
        .box_pay_sec {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #ddd;
          margin-top: 1em;
          padding-top: 2.4rem;
          .dinfotex {
            text-align: center;
            img {
              max-height: 40px;
            }
            div {
              font-size: 0.6rem;
              text-transform: uppercase;
              letter-spacing: 1px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .drawer_info_aprob {
    text-align: center;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 2px dashed #239950;
    color: #239950;
    background: #23995013;
    max-width: 500px;
    margin: 1rem auto;
  }
}
.file_wrapper{


[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #f2f2f2;
  width: 100%;
  border: 1px dashed #ccc;
  border-radius: .5rem;
  cursor: pointer;
  // display: inline-block;
  margin-bottom: .25rem;
  outline: none;
  padding: 1.5rem .5rem;
  position: relative;
  transition: all 0.3s;
  // vertical-align: middle;
  text-align: center;
  &:hover {
  color: #ffffff;
  background-color: var(--primary_color);
  }
}
.filein{
  color: #888888;
}
.notesmall{
  text-align: center;
  padding: 0 1rem;
  color: var(--primary_color);
  line-height: 1;
}
}